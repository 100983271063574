<template>
  <v-container fluid>
    <v-form ref="base">
      <v-card class="card main-container">
        <v-layout
          justify-space-between
          row
          class="form-main-header"
          style="padding: 0"
        >
          <h1 v-if="!emptyNameFields">
            {{ row.firstName }} {{ row.lastName }}
          </h1>
          <h1 v-else>Add User</h1>
          <div class="button-container top-items">
            <v-btn
              v-if="(isModeView || isModeEdit) && isAdmin"
              :id="`users-form-button-reset-password`"
              class="btn-secondaryaction"
              @click="changePassword"
            >
              Change Password
            </v-btn>
            <v-btn
              :id="`users-form-button-cancel-action`"
              class="btn-secondaryaction"
              @click="cancelAction"
            >
              {{ isModeView ? 'All Users' : 'Cancel' }}
            </v-btn>
            <v-btn
              v-if="isModeEdit"
              :id="`users-form-button-save-user`"
              :disabled="loading"
              :loading="loading"
              class="btn-primaryaction"
              @click="submit"
            >
              Save User
            </v-btn>

            <v-btn
              v-if="isModeAdd"
              :id="`users-form-button-add-user`"
              :disabled="loading"
              :loading="loading"
              class="btn-primaryaction"
              @click="submit"
            >
              Add User
            </v-btn>

            <v-btn
              v-if="isModeView"
              :id="`users-form-button-edit-user`"
              class="btn-primaryaction"
              @click="enableEdit"
            >
              Edit User
            </v-btn>
          </div>
        </v-layout>
        <v-divider />
        <v-layout
          row
          wrap
          justify-center
          align-end
          style="max-width: 1200px; margin: 0 auto"
        >
          <v-flex column xs12 sm6>
            <div class="avatar-group">
              <input
                :id="`users-form-input-upload-file`"
                :disabled="disabled"
                accept="image/*"
                class="avatar-input"
                type="file"
                @change="uploadFile"
              />
              <v-avatar class="the-avatar" size="90">
                <img v-if="avatarLink" :src="avatarLink" />
                <v-icon v-if="!avatarLink" color="blue" size="50">
                  person_outline
                </v-icon>
              </v-avatar>
              <div v-if="!avatarLink" class="avatar-text">
                Add Profile Image
              </div>
              <div v-else class="avatar-text">Change Profile Image</div>
            </div>
          </v-flex>
          <v-flex column xs12 sm6>
            <CRInput
              :id="`users-form-input-company-name`"
              v-model="row.companyName"
              :disabled="true"
              floatinglabel="Company Name"
              style="margin-top: 10px"
              :class="$cr.breakpoint.smAndUp ? 'padding-l-2' : ''"
              @change="syncInput"
            />
          </v-flex>
          <v-flex
            column
            xs12
            sm6
            :class="$cr.breakpoint.smAndUp ? 'padding-r-2' : ''"
          >
            <CRInput
              :id="`users-form-input-first-name`"
              v-model="row.firstName"
              :disabled="disabled"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'First Name Is Required',
                  error: 'First Name Is Required',
                }),
              ]"
              floatinglabel="First Name"
              @change="syncInput"
            />
          </v-flex>
          <v-flex
            column
            xs12
            sm6
            :class="$cr.breakpoint.smAndUp ? 'padding-l-2' : ''"
          >
            <CRInput
              :id="`users-form-input-last-name`"
              v-model="row.lastName"
              :disabled="disabled"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Last Name Is Required',
                  error: 'Last Name Is Required',
                }),
              ]"
              floatinglabel="Last Name"
              @change="syncInput"
            />
          </v-flex>
          <v-flex column xs12 sm6>
            <CRInput
              :id="`users-form`"
              v-model="row.email"
              :disabled="disabled"
              :rules="[
                isRequired(true, validateEmail, {
                  req: 'Email Required',
                  error: 'Email must be valid',
                }),
              ]"
              floatinglabel="Email"
              :class="{ 'padding-r-2': $cr.breakpoint.smAndUp }"
              style="margin-top: 2px"
              @change="syncInput"
            />
          </v-flex>
          <v-flex column xs6 xs12 sm6>
            <CRInput
              :id="`users-form-phone`"
              v-model="row.phone"
              :disabled="disabled"
              floatinglabel="Phone"
              :class="{ 'padding-l-2': $cr.breakpoint.smAndUp }"
              style="margin-top: 2px"
              @change="syncInput"
            />
          </v-flex>
          <v-flex column xs12 sm6>
            <label>User Group</label>
            <div id="users-form-select-group-container">
              <CRSelect
                :id="`users-form-select-group-id`"
                v-model="row.groupId"
                solo
                flat
                item-value="groupId"
                item-text="groupName"
                :rules="[
                  isRequired(true, isNotEmpty, {
                    req: 'User Group Is Required',
                    error: 'User Group Is Required',
                  }),
                ]"
                :disabled="isModeView ? true : groupIdVerify"
                :items="USER_GROUPS"
                :class="$cr.breakpoint.smAndUp ? 'padding-r-2' : ''"
                @change="getGroupAndSyncInput"
              />
            </div>
          </v-flex>
          <v-flex v-if="isAdmin" column xs6>
            <label>Application</label>
            <div id="users-form-select-group-container">
              <CRSelect
                :id="`users-form-select-group-id`"
                v-model="row.applicationId"
                solo
                flat
                :disabled="disabled"
                item-value="applicationId"
                item-text="applicationName"
                :rules="[
                  isRequired(true, isNotEmpty, {
                    req: 'Application Is Required',
                    error: 'Application Is Required',
                  }),
                ]"
                :items="APPLICATIONS"
                :class="$cr.breakpoint.smAndUp ? 'padding-l-2' : ''"
                @change="syncInput"
              />
            </div>
          </v-flex>
          <v-flex xs6>
            <v-checkbox
              v-if="row.groupId === 1 || row.groupId === 2"
              id="users-form-checkbox-account-executive"
              v-model="isAccountExecutive"
              label="This user is an account executive"
              :disabled="disabled"
            />
          </v-flex>
          <v-flex xs6>
            <v-checkbox
              v-if="row.groupId === 1 || row.groupId === 2"
              id="users-form-checkbox-assign-tickets"
              v-model="canBeAssignedTickets"
              label="This user can be assigned tickets"
              :disabled="disabled"
            />
          </v-flex>
        </v-layout>
        <v-tabs v-if="!isSDR">
          <v-tab
            v-for="(tab, tabIdx) in tabs"
            :key="`driver-components-${tabIdx}`"
            ripple
          >
            {{ tab.label }}
          </v-tab>

          <v-tab-item v-for="(tab, tabItemIdx) in tabs" :key="tabItemIdx">
            <component
              :is="tab.component"
              :id="`users-form-component-${tabItemIdx}`"
              :ref="tab.ref"
              :mode="mode"
              :row="row"
              :user-id="id"
              :is-user-driver="false"
              :selected-roles="roleSelections"
              :is-driver-sidebar="false"
              @user-role-change="roleSelections = $event"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-form>
  </v-container>
</template>
<script>
import { authComputed } from '@/state/helpers'
import { baseUrl } from '@/utils/env'
import { isNotEmpty, isRequired, validateEmail } from '@/utils/validators'
import { mapActions, mapGetters } from 'vuex'
import UsersFormRoleTab from '@/components/UsersFormRoleTab.vue'
import UserAuditList from '@/components/UserAuditList.vue'
import drivers from '@/services/drivers'
import companies from '@/services/companies'
import roles from '@/services/roles'

const USER_GROUPS = [
  {
    groupId: 1,
    groupName: 'Admin',
    key: 'admin',
  },
  {
    groupId: 2,
    groupName: 'User',
    key: 'user',
  },
  {
    groupId: 6,
    groupName: 'Agent',
    key: 'agent',
  },
]

const APPLICATIONS = [
  {
    applicationId: 1,
    applicationName: 'CoachRail',
  },
  {
    applicationId: 3,
    applicationName: 'CharterUP for Operators',
  },
]

export default {
  name: 'UsersForm',
  props: {
    id: {
      type: String,
      default: () => null,
    },
    mode: {
      type: String,
      default: () => 'view',
    },
    companyId: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      USER_GROUPS,
      APPLICATIONS,
      tabs: [
        {
          label: 'Logs',
          ref: 'logs',
          component: UserAuditList,
        },
      ],
      loading: false,
      disabled: false,
      avatarLink: null,
      uploadedFile: null,
      row: {
        companyId: null,
        companyName: '',
        email: '',
        phone: '',
        groupId: null,
      },
      groupIdVerify: false,
      roles: [],
      roleSelections: [],
      canBeAssignedTickets: false,
      isAccountExecutive: false,
      oldGroupId: null,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      userState: 'users/getUser',
    }),
    isModeView() {
      return this.mode === 'view'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isAdmin() {
      return this.currentUser?.group?.groupId === 1
    },
    emptyNameFields() {
      var isFirstNameEmpty =
        this.row.firstName == null || this.row.firstName === ''
      var isLastNameEmpty =
        this.row.lastName !== null || this.row.lastName !== ''
      return isFirstNameEmpty && isLastNameEmpty
    },
  },
  watch: {
    canBeAssignedTickets(newValue) {
      if (
        this.roleSelections.includes('can_be_assigned_tickets') &&
        !newValue
      ) {
        this.roleSelections.splice(
          this.roleSelections.indexOf('can_be_assigned_tickets'),
          1
        )
      }
      if (
        !this.roleSelections.includes('can_be_assigned_tickets') &&
        newValue
      ) {
        this.roleSelections.push('can_be_assigned_tickets')
      }
    },
    isAccountExecutive(newValue) {
      if (this.roleSelections.includes('is_account_executive') && !newValue) {
        this.roleSelections.splice(
          this.roleSelections.indexOf('is_account_executive'),
          1
        )
      }
      if (!this.roleSelections.includes('is_account_executive') && newValue) {
        this.roleSelections.push('is_account_executive')
      }
    },
  },
  metaInfo() {
    const meta = {}
    if (this.isModeEdit) {
      meta.title = `Edit: ${this.row.firstName ? this.row.firstName : ''} ${
        this.row.lastName ? this.row.lastName : ''
      }`
    }
    if (this.isModeView) {
      meta.title = `${this.row.firstName ? this.row.firstName : ''} ${
        this.row.lastName ? this.row.lastName : ''
      }`
      return meta
    }
  },
  async mounted() {
    this.groupIdVerify =
      this.currentUser && this.currentUser.group.groupId
        ? this.currentUser.group.groupId !== 1
        : true
    this.disabled = this.isModeView

    this.row = {}

    if (!this.isModeAdd) {
      let user = {}

      user = await this.$store
        .dispatch('users/getUser', this.id)
        .then((data) => {
          return data.data.user
        })
        .catch(() => ({}))

      this.avatarLink = user.userPhotoDTOs?.length
        ? `https://${baseUrl(null)}${
            user.userPhotoDTOs[user.userPhotoDTOs.length - 1].imagePath
          }`
        : null

      this.row = Object.assign({}, user)
    }

    this.oldGroupId = this.row.groupId

    await this.$store.dispatch('users/setInitialUser', this.row)

    if (this.isModeAdd) {
      this.row.companyName = this.currentUser.companyName
      this.row.companyId = this.currentUser.companyId

      if (this.isAdmin) {
        const targetCompany = await companies
          .getCompany(this.companyId || this.currentUser.companyId)
          .then((data) => data.data?.company)
          .catch((e) => {
            this.row.companyName = this.currentUser.companyName
            this.row.companyId = this.currentUser.companyId
          })

        this.row.applicationId = targetCompany.cupopsEnabled ? 3 : 1
        this.row.companyName = targetCompany.name
        this.row.companyId = this.companyId || this.currentUser.companyId
      }
    }

    const roleResponse = await roles.getRoles({
      page: 1,
      pageSize: -1,
    })
    this.roles = roleResponse?.data?.resultList

    if (this.id) {
      const userRoleResponse = await roles.getUserRoles(this.id)
      const userRoles = userRoleResponse?.data?.roles
      userRoles.forEach((role) => this.roleSelections.push(role.roleName))
    }

    this.canBeAssignedTickets = this.roleSelections.includes(
      'can_be_assigned_tickets'
    )
    this.isAccountExecutive = this.roleSelections.includes(
      'is_account_executive'
    )

    if (this.canModifyUserRoles) {
      this.tabs.push({
        label: 'Roles',
        ref: 'roles',
        component: UsersFormRoleTab,
      })
    }
  },
  methods: {
    isRequired,
    isNotEmpty,
    validateEmail,
    ...mapActions({ showAlert: 'app/showAlert' }),
    displayErr(e, status) {
      this.showAlert({
        message: e,
        type: 'error',
      })
    },
    uploadFile(evt) {
      const file = evt.target.files[0]
      this.avatarLink = URL.createObjectURL(file)

      const formData = new FormData()
      formData.append('file', file)
      this.uploadedFile = formData
    },
    changePassword() {
      this.$store.dispatch('app/openDialog', {
        component: () => import('@/components/ChangePasswordForm.vue'),
        data: {
          userId: this.id,
          isAdmin: this.isAdmin,
        },
      })
    },
    disableAndRevert() {
      this.disabled = true
      this.$router.push({ name: 'users.view', params: { id: this.id } })
    },
    async getGroupAndSyncInput() {
      if (!this.isModeEdit) {
        return
      }
      if (this.row.groupId !== this.oldGroupId) {
        let determineRoles = {}
        determineRoles.userId = this.id
        determineRoles.newUserGroupId = this.row.groupId
        determineRoles.oldUserGroupId = this.oldGroupId
        determineRoles.newRoleNames = this.roleSelections

        const userRolesResponse = await this.$store
          .dispatch('users/determineRoles', {
            payload: determineRoles,
          })
          .catch((_) => ({}))
        this.roleSelections = userRolesResponse.data?.roleNames
        this.oldGroupId = this.row.groupId
      }
      this.syncInput()
    },
    async syncInput() {
      await this.$store.dispatch('users/setUser', this.row)
    },
    async submit() {
      this.loading = true
      const baseValidate = this.$refs.base.validate()
      if (baseValidate === false) {
        this.loading = false
        this.displayErr(
          'Some fields are missing that may be Required. or are invalid.',
          true
        )
        return
      }

      let fetchEmail = await this.$store.dispatch(
        'users/checkEmail',
        this.row.email
      )
      let doesEmailExist = fetchEmail?.data?.exists

      if (doesEmailExist && this.isModeAdd) {
        this.displayErr('Email already exists, please use another one.', true)
        this.loading = false
        return
      }

      const copyOfState = JSON.parse(JSON.stringify(this.userState))
      copyOfState.groupId = this.row.groupId

      copyOfState.userRoleNames = this.roleSelections

      if (
        this.canBeAssignedTickets &&
        !this.roleSelections.includes('can_be_assigned_tickets')
      ) {
        copyOfState.userRoleNames.push('can_be_assigned_tickets')
      }
      if (
        this.isAccountExecutive &&
        !this.roleSelections.includes('is_account_executive')
      ) {
        copyOfState.userRoleNames.push('is_account_executive')
      }

      try {
        let action
        action = this.isModeAdd ? 'users/createUser' : 'users/updateUser'
        let updatedUser
        updatedUser = await this.$store.dispatch(action, {
          id: this.id,
          payload: copyOfState,
        })

        if (this.uploadedFile) {
          const userId =
            updatedUser.data === 'number'
              ? updatedUser.data
              : updatedUser.data?.user?.userId
          const driverId = typeof userId === 'number' ? userId : this.id
          await drivers
            .uploadDriverPhoto({ payload: this.uploadedFile, driverId })
            .catch(() => ({}))
        }
      } catch (e) {
        this.loading = false
        if (e.message.includes('403')) {
          this.displayErr('You are not authorized to edit this user.', true)
        } else {
          this.displayErr(e, true)
        }
        return
      }

      this.loading = false
      this.disabled = !this.disabled

      const userType = 'users'
      this.$router.push(`/${userType}${this.id ? `/view/${this.id}` : ''}`)
      this.showAlert({
        message: 'User settings saved successfully.',
        type: 'success',
      })
    },
    enableEdit() {
      if (!this.isModeEdit) {
        this.$router.push({ name: 'users.edit', params: { id: this.id } })
      }

      this.disabled = !this.disabled
    },
    backToTable() {
      this.$router.push({ name: 'users' })
    },
    cancelAction() {
      if (this.isModeEdit) {
        this.disableAndRevert()
        return
      }

      this.backToTable()
    },
  },
}
</script>
<style lang="scss" scoped>
.avatar-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3%;

  .avatar-input {
    position: absolute;
    z-index: 100;
    width: 150px;
    height: 150px;
    cursor: pointer;
    opacity: 0;
  }

  .avatar-text {
    margin-top: 2%;
    color: $primary;
    cursor: pointer;
  }

  .the-avatar {
    border: 3px solid $primary;
  }
}

@media screen and (max-width: 767px) {
  .form-container {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    flex-direction: column-reverse;
    width: 100%;
  }
}

.card {
  padding: 30px;
  background-color: $white;
  border-radius: 15px;
}

::v-deep .input-space.description {
  margin-top: 20px;
}

::v-deep input[disabled],
::v-deep input:disabled,
::v-deep textarea[disabled] {
  cursor: not-allowed;
  background-color: $blue-dull !important;
}

.top-items {
  position: absolute;
  top: -5px;
  right: -8px;
}

.main-container {
  box-shadow: none !important;
}
</style>
