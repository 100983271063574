<template>
  <BaseTableWrapper
    :special-text-formatting="specialTextFormatting"
    :table-init="tableInit"
    :is-admin="isAdmin"
    :columns="columns"
    :initial-filters="initialFilters"
  >
    <h1 class="page-header"></h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { friendlyDate } from '@/utils/time'
import { authComputed } from '@/state/helpers'
import { DateTime } from 'luxon'
import { calculatedValues, datePredefined } from '@/utils/predefined'
import { deepClone } from '@/utils/deepClone'

export default {
  components: {
    BaseTableWrapper,
  },
  props: {
    userId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      initialFilters: [],
      isAdmin: this.isAdmin,
      specialTextFormatting: {
        createdAt: friendlyDate,
      },
      columns: [],
      tableInit: {
        enableSavedViews: false,
        enableStatusFilterToggle: true,
        enableColumnConfig: false,
        detailKeyId: 'userAuditId',
        tableId: 'user_details_audits_tv_view',
        addNewEnabled: false,
        currentPage: 1,
        perPage: 10,
        calculatedValues,
        initialFilters: [],
        columns: [],
        collection: 'UserAudits',
        additionalQueries: this.userId,
      },
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const user = this.currentUser
    this.isAdmin = user?.group?.key === 'admin'
    await this.getColumns()
  },
  methods: {
    async getColumns() {
      const columnsOut = [
        {
          _t_id: 'e789bd96-7b5c-40cb-a5a0-ec7287e0d92d',
          prop: 'userAuditId',
          text: 'Audit ID',
          sort: true,
          filter: false,
          type: 'number',
          sortProp: 'userAuditId',
          filterType: 'eq',
          defaultSort: true,
        },
        {
          _t_id: 'f3cd2d88-8dfb-4726-9ccd-b17dd391886d',
          prop: ['user/firstName', 'user/lastName'],
          text: 'User',
          sort: true,
          filter: false,
          type: 'text',
          sortProp: 'user/lastName',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
        },
        {
          _t_id: 'b429e1dc-2849-4920-bb2b-1b82f9f039e1',
          prop: 'datetime',
          text: 'Time',
          computedText: (item) =>
            DateTime.fromISO(item).toFormat('MM/dd/yyyy HH:mm'),
          sort: true,
          filter: false,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: '1d3993e0-9e2d-47bb-9f81-47144eb00d2a',
          prop: 'actionType',
          text: 'Action Type',
          sort: true,
          filter: false,
          type: 'text',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          sortProp: 'actionType',
        },
        {
          _t_id: 'e778ac6a-5e7b-4c56-9080-00ada728aa90',
          prop: 'previousValue',
          text: 'Previous Value',
          sort: true,
          filter: false,
          type: 'text',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          sortProp: 'previousValue',
        },
        {
          _t_id: '9ca8f729-8edd-4737-9d24-b36ded73a565',
          prop: 'newValue',
          text: 'New Value',
          sort: true,
          filter: false,
          type: 'text',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          sortProp: 'newValue',
        },
      ]

      return (this.columns = columnsOut)
    },
  },
}
</script>
