<template>
  <v-container fluid>
    <div>User Roles</div>
    <v-form ref="roles">
      <v-layout row wrap>
        <v-flex column class="width-30" xs6>
          <div id="users-form-select-group-container">
            <CRSelect
              :id="`users-form-role-selector`"
              ref="userRoles"
              v-model="roleSelections"
              :disabled="disabled"
              chips
              flat
              multiple
              solo
              :items="roles"
              class="role-selector"
              hide-details
              item-text="roleName"
              item-value="roleName"
            />
          </div>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>
<script>
import roles from '@/services/roles'
export default {
  props: {
    mode: {
      type: String,
      default: () => 'view',
    },
    userId: {
      type: String,
      default: () => '',
    },
    selectedRoles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      roles: [],
      roleSelections: [],
    }
  },
  computed: {
    disabled() {
      return this.mode === 'view'
    },
  },
  watch: {
    selectedRoles() {
      if (this.selectedRoles.length) {
        this.roleSelections = this.selectedRoles
      }
    },
    roleSelections(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('user-role-change', this.roleSelections)
      }
    },
  },
  async mounted() {
    const roleResponse = await roles.getRoles({
      page: 1,
      pageSize: -1,
    })
    this.roles = roleResponse?.data?.resultList.filter((role) => !role.custom)

    const userRoleResponse = await roles.getUserRoles(this.userId)
    const userRoles = userRoleResponse?.data?.roles
    userRoles.forEach((role) => this.roleSelections.push(role.roleName))
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.form-container {
  background-color: $blue-light;
}

.role-selector {
  color: $primary;
  background-color: $white;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 10px;
}

.role-selector::v-deep {
  .v-input__control {
    min-height: 42px;
  }

  .theme--light.v-chip {
    color: $white !important;
    background: $primary !important;
  }
}

.amenities {
  flex-wrap: wrap;
  .amenity-box {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
  }
}

.input-margins {
  margin-right: 4px;
}

.amenities > .checkbox > .v-input__control > .v-input__slot {
  background-color: transparent !important;
}

::v-deep .v-input--checkbox > .v-input__control > .v-input__slot {
  background-color: transparent !important;
}
</style>
